@import '../../variables/color';

.loading-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1001;
  background-color: $overlayLight;
  color: $primary;

  .icon {
    font-size: 32px; } }
