@import '../../variables/color';

.sidebar-wrapper {
  display: flex;
  height: calc(100vh - 80px);
  background-color: $light;
  border-right: 1px solid $softGray;

  .sidebar {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 20px 0;

    .sidebar-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      padding: 8px 10px;
      border-left: 5px solid transparent;

      &:hover {
        text-decoration: none; }

      &.active {
        border-left-color: $primary;
        color: $primary;
        background-color: $white; }

      .icon {
        display: flex;
        min-width: 25px;
        margin-right: 5px; } } } }
