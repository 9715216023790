@import '../../variables/color';

.header-wrapper {
  min-height: 80px;
  border-bottom: 1px solid $softGray;

  .logo-container {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: $light;
    border-right: 1px solid $softGray; }

  .logo {
    max-width: 150px; }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    .account-button {
      .account-toggle {
        width: 50px;
        padding: 6px 0;
        border: 1px solid $black;
        border-radius: .25rem; }

      a {
        color: $black;
        text-decoration: none;

        &:hover, &:active, &:focus {
          background-color: transparent;
          color: $black; } } } } }
