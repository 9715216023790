@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'variables/color';

a:focus {
  outline: none!important;
  box-shadow: none!important; }

.page-title {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 24px;
  font-weight: 500;

  span {
    display: flex;
    width: 100%;

    &.subtitle {
      font-size: 13px;
      font-weight: 400;
      color: $darkGray; } } }

.page-button {
  display: flex;
  margin: 30px 0;
  justify-content: flex-end;

  button {
    margin: 10px 0;
    .icon {
      margin-right: 5px; } } }

.general-table {
  .actions {
    button {
      margin-right: 5px; } } }

.primary-button {
  background-color: $primary!important;
  border-color: $primary!important; }

.primary-outline-button {
  background-color: transparent!important;
  border-color: $primary!important;
  color: $primary!important; }

.secondary-button {
  background-color: $secondary!important;
  border-color: $secondary!important; }

.secondary-outline-button {
  background-color: transparent!important;
  border-color: $secondary!important;
  color: $secondary!important; }

.tertiary-button {
  background-color: $tertiary!important;
  border-color: $tertiary!important; }

.tertiary-outline-button {
  background-color: transparent!important;
  border-color: $tertiary!important;
  color: $tertiary!important; }
