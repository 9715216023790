$primary: #9357cc;
$secondary: #2989d8;
$tertiary: #2cc99d;
$softLight: #d3e0ea;
$light: #f6f5f5;

$white: #ffffff;
$black: #252525;
$darkGray: #888;
$softGray: #e3e3e3;

$overlayLight: rgba(255, 255, 255, 0.5);
$overlayDark: rgba(39, 102, 120, 0.5);
