@import '../../variables/color';

.login-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .button-container {
    display: flex;
    flex-direction: row;

    .action-button {
      background-color: transparent;
      color: $black; }


    .link {
      display: flex;
      justify-content: flex-end;
      color: $black; } } }
